.card__btn {
  width: fit-content;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-transform: uppercase;

  font-weight: 500;

  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.card__btn:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff !important;
  transform: translateY(-7px);
}

span {
  font-family: "Roboto,sans-serif";
  letter-spacing: 1.5px;
  font-size: 16px;
}
