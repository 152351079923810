.header {
  overflow-x: hidden;
  justify-content: center;
}
.header::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.header::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.header::-webkit-scrollbar-thumb {
  width: 20px;
  background-color: rgba(0, 10, 0, 0.1);
}
.header__link:hover {
  color: darkgrey !important;
}
