.nav__col {
  height: 100vh;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.6s ease;
  padding-top: 20px;
  width: 300px;
  flex-shrink: 0;
  overflow-y: scroll;
}

@media (max-width: 750px) {
  .nav__col {
    position: absolute;
    z-index: 4;
    left: -310px;
    transition: 0.5s all ease;
  }
  .unhide {
    position: absolute;
    z-index: 4;
    left: 0px;
  }
}

@media (min-width: 751px) {
  .hide {
    margin-left: -300px;
  }
}
.nav__col::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.nav__col::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

img {
  display: block;
  object-fit: contain;
  width: 50%;
  margin: auto;
  border-radius: 50%;
  /* margin-bottom: 20px; */
}

.nav__text {
  margin-top: 20px;
  display: inline-block;
}
.nav__social {
  display: flex;

  justify-content: center;
  margin: 20px auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
}
.nav__social__icons:not(:nth-of-type(4)) {
  margin-right: 16px;
}
.nav__social__icons {
  font-size: x-large;

  margin-bottom: 30px;
  margin-top: 10px;
  transition: 0.2s ease !important;
}

.nav__social__icons:hover {
  transform: translateY(2px) !important;
  transform: scale(1.08) !important;
}

.nav__resumebtn {
  background-color: rgba(0, 0, 0, 0.3) !important;
  display: block !important;

  font-weight: 600 !important;
  border-radius: 4px !important;
  outline: none !important;
  border: none !important;
  margin: 30px auto !important;
}

.nav__resumebtn:hover {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.nav__resumebtn:active {
  transform: translateY(2px) !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
}
.nav__darkMode {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 20vh;
}

.nav__themeSelect {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  width: fit-content;
  padding: 8px;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 5px;
  top: 120px;
  right: -170px;
  z-index: 2;
}

.nav__themeSelect:hover {
  cursor: pointer;
  right: -10px !important;
}

.nav__themeBtn {
  margin: 5px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.nav__themeBtn:hover {
  cursor: pointer;
  border: 2px solid white;
  transform: scale(1.08);
}

.nav__themeBtn:active {
  transform: translateY(2px);
}
