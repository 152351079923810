.menu {
  position: fixed;
  width: fit-content;
  right: 15px;
  top: 20px;
  z-index: 100;
  transition: 0.1s ease;
  opacity: 1;
}
.menu__btn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 4px 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}
.menu__btn:hover {
  color: rgba(0, 0, 0, 0.9);
}
.menu__btn:active {
  transform: translateY(2px);
  box-shadow: none;
}
.menu__btn > * {
  cursor: pointer;
  font-size: 25px;
}

.hide {
  opacity: 0;
}
