@import "~antd/dist/antd.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  letter-spacing: 1.5px;
  font-family: "Roboto,sans-serif";
  max-width: 1600px;
  margin: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  letter-spacing: 1.5px;
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
} /* html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
h1 {
  font-size: 3rem;
}
h2,
h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}
p {
  font-size: 1.5rem;
}
.App {
} */
